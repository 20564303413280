import React from "react"
import { graphql } from "gatsby"
import cx from "classnames"
import CyrillicToTranslit from "cyrillic-to-translit-js"
import { ParallaxProvider } from "react-scroll-parallax"
import { useTranslation } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { renderHtmlBlocks } from "../helpers/render"
import Heading from "../components/Elements/Heading"
import Breadcrumbs from "../components/Breadcrumbs"
import Form from "../components/Form"
import { getCustomStyles } from "../helpers/styles"

import "../css/@wordpress/block-library/build-style/style.css"

const CaseTemplate = ({ data: { currentCase }, pageContext }) => {
  const { t } = useTranslation()
  const seo = currentCase?.seo
  const settings = currentCase?.settings
  const breadcrumbToClass =
    "case-" +
    CyrillicToTranslit()
      .transform(currentCase.title)
      .toLowerCase()
      .replace(/\W/g, "-")

  return (
    <ParallaxProvider>
      <Layout>
        <Seo
          title={seo?.title || currentCase.title}
          description={seo?.metaDescription || currentCase.excerpt}
          keywords={seo?.metaKeywords || ""}
        />
        {getCustomStyles(settings)}
        <article
          className={cx("case", breadcrumbToClass)}
          itemScope
          itemType="http://schema.org/Article"
        >
          {!!currentCase.content && (
            <section itemProp="articleBody">
              <div className="header-container">
                <div className="page_titles">
                  <Breadcrumbs pageContext={pageContext} />
                  <Heading pageTitle>
                    {t("Кейс")} {currentCase.title}
                  </Heading>
                </div>
              </div>
              {renderHtmlBlocks(currentCase.content)}
              <Form />
            </section>
          )}
        </article>
      </Layout>
    </ParallaxProvider>
  )
}

export default CaseTemplate

export const pageQuery = graphql`
  query CaseById($id: String!, $language: String!) {
    currentCase: wpCase(id: { eq: $id }) {
      content
      title
      seo {
        metaDescription
        metaKeywords
        title
      }
      settings {
        customStyles
        rbeaStyles
        language
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
